<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button @click="getinfo" size="mini">刷新</el-button>
				</li>
			</ul>
		</div>

		<div class="content">
			<div class="title"><span></span>数据统计</div>
			<div class="list">
				<div class="li">
					<div class="bg">
						<p>今日录入工单</p>
						<div>{{setdata.today || 0}}
						</div>
						<i class="i6 icon iconfont icon-rizhi"></i>
					</div>
				</div>
				<div class="li">
					<div class="bg">
						<p>月录入工单</p>
						<div>{{setdata.month || 0}}
						</div>
						<i class="i4 icon iconfont icon-jingqingluru"></i>
					</div>
				</div>
				<div class="li">
					<div class="bg">
						<p>年录入工单</p>
						<div>{{setdata.year || 0}}
						</div>
						<i class="i3 icon iconfont icon-suanfazongshu"></i>
					</div>
				</div>

				<div class="li">
					<div class="bg">
						<p>总录入工单</p>
						<div>{{setdata.total || 0}}
						</div>
						<i class="i2 icon iconfont icon-qiyeyewu"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ElNotification
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/user";
	import NProgress from 'nprogress'
	export default {
		data() {
			return {
				setdata: {}
			}
		},
		created() {
			this.getinfo()
		},
		methods: {
			// 获取信息
			getinfo() {
				NProgress.start()
				this.$http.post(api.statistics)
					.then((res) => {
						if (res.code == 1) {
							this.setdata = res.data
						}
					})

			},
		}
	}
</script>

<style lang="scss" scoped>
	.title {
		margin-top: 30px;
		color: #000;
		font-size: 15px;
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		padding-left: 10px;

		span {
			height: 12px;
			width: 3px;
			margin-right: 5px;
			background: #5473E8;
			display: inline-block;
		}
	}

	.list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.li {
			width: 25%;
			padding: 10px;

			.bg {
				box-shadow: 0 3px 13px 0 rgb(0 0 0 / 11%);
				background-color: #FFFFFF;
				padding: 20px;
				font-size: 14px;
				border-radius: 8px;
				position: relative;

				i {
					position: absolute;
					right: 20px;
					width: 44px;
					height: 44px;
					text-align: center;
					line-height: 44px;
					color: #ffffff;
					top: 50%;
					font-size: 22px;
					margin-top: -22px;
					display: inline-block;
					border-radius: 50%;
				}

				.i0 {
					background: -webkit-linear-gradient(#6887fa, #5473E8);
					background: -o-linear-gradient(#6887fa, #5473E8);
					background: -moz-linear-gradient(#6887fa, #5473E8);
					background: linear-gradient(#6887fa, #5473E8);

				}

				.i1 {
					background: -webkit-linear-gradient(#ffc937, #ffb032);
					background: -o-linear-gradient(#ffc937, #ffb032);
					background: -moz-linear-gradient(#ffc937, #ffb032);
					background: linear-gradient(#ffc937, #ffb032);

				}

				.i2 {
					background: -webkit-linear-gradient(#a9e641, #6bd126);
					background: -o-linear-gradient(#a9e641, #6bd126);
					background: -moz-linear-gradient(#a9e641, #6bd126);
					background: linear-gradient(#a9e641, #6bd126);
				}

				.i3 {
					background: -webkit-linear-gradient(#3facda, #2f8de2);
					background: -o-linear-gradient(#3facda, #2f8de2);
					background: -moz-linear-gradient(#3facda, #2f8de2);
					background: linear-gradient(#3facda, #2f8de2);
				}

				.i4 {
					background: -webkit-linear-gradient(#a75acd, #7c4cc6);
					background: -o-linear-gradient(#a75acd, #7c4cc6);
					background: -moz-linear-gradient(#a75acd, #7c4cc6);
					background: linear-gradient(#a75acd, #7c4cc6);
				}

				.i5 {
					background: -webkit-linear-gradient(#46c8d6, #30b5d4);
					background: -o-linear-gradient(#46c8d6, #30b5d4);
					background: -moz-linear-gradient(#46c8d6, #30b5d4);
					background: linear-gradient(#46c8d6, #30b5d4);
				}

				.i6 {
					background: -webkit-linear-gradient(#ff5b3d, #ff2b67);
					background: -o-linear-gradient(#ff5b3d, #ff2b67);
					background: -moz-linear-gradient(#ff5b3d, #ff2b67);
					background: linear-gradient(#ff5b3d, #ff2b67);
				}

				div {
					margin-top: 15px;
					font-size: 20px;
				}
			}
		}
	}

	.content {
		padding: 20px;
	}
</style>
